@import '../../AppConstants.scss';

.row {
    width: auto;
    background-color: $primary-color-3;
    display: flex;
    border-bottom: 1px solid rgb(190, 190, 190);
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    padding-inline: 20px;
    flex-wrap: wrap;

    .title {
        color: $primary-color-2;
        font-size: 16px;
        font-weight: 600;
    }
    
    .property{
        width: 300px;
    }

    .value {
        color: $primary-color-2;
        font-size: 16px;
        font-weight: 400;
    }

    &:hover{
        cursor: pointer;
        background-color: rgb(224, 224, 224)
    }
}