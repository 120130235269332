@import '../../AppConstants.scss';

.container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: $primary-color-3;
    padding-bottom: 50px;
    color:$primary-color-2;

    span{
        display: block;
    }
    .col{
        width: 300px;

        .line{
            font-weight: 600;
            margin:5px;

            .value{
                font-weight: 400;
                display: inline-block;
            }
        }

        .buttons{
            margin-top:30px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        .list{
            overflow-y:scroll;
            height: 300px;
            background-color: rgba(236, 236, 236, 0.342);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.459);

            .row{
                text-align: center;
                background-color: rgb(236, 236, 236);
                padding: 5px;
                border-bottom: 1px solid rgb(201, 201, 201);

                &:hover{
                    background-color: $primary-color-4;
                    cursor: pointer;
                }
            }
        }
    }
}
