@import '../../AppConstants.scss';


.button{
    height: 35px;
    padding-inline: 10px;
    border: none;
    border-radius: 5px;
    background-color: $primary-color-2-light;
    color: $primary-color-3;
    font-weight: 600px;
    font-size: 18px;
    transform: translate(0px,-2px);
    box-shadow: 0px 2px 2px black;

    &.warn{  
        background-color: red;
    }
    &.primary{  
        background-color: $primary-color-1;
    }

    &:hover{
        transform: none;
        box-shadow: none;
        cursor: pointer;
    }
    &:active{
        transform: none;
        box-shadow: none;
        background-color: $primary-color-2-dark;
        cursor: pointer;
    }
}