@import '../../AppConstants.scss';

.container {
    width: auto;
    height: 60px;
    background-color: $primary-color-4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px -1px 0px $structure-color-7;

    .containerLeft {
        display: flex;
        align-items: center;
        margin-left: 20px;

        .item {
            font-family: 'Open Sans';
            font-size: 26px;
            line-height: 26px;
            color: $primary-color-2;
            margin-left: 10px;
        }
    }

    .containerRight {
        display: flex;
        margin-right: 20px;
    }
}

.arrowLeft {
    color: $primary-color-2;
}

.logoutLogo {
    color: $primary-color-2;
}