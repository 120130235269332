@import '../../AppConstants.scss';




.container{
    height: 100%;
    position:fixed;
    z-index: 100;
    top:0;
    left: 0;
    background-color: $primary-color-2-dark;
    width:186px;
    transition:0.5s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    &.collapsed{
        width: 82px;
        transition:0.5s;
    }
}



.logo{
    margin-left: 53px;
    margin-top:20px;
    margin-bottom:10px;
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 0px 7px rgb(255, 209, 2));
}
.logoCollapsed{
    margin-left: 10px;
    margin-top:20px;
    margin-bottom:20px;
}

.resizeIcon{
    color:$primary-color-3;
    width:30px;
    height:30px;
    float: right;
    padding:20px;
    
    &.expand{
        padding-left: 29px;
        padding-right: 29px;
    }
}





