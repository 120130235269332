$primary-color-1:#F23DC2;
$primary-color-2:#3A1B59;
$primary-color-2-dark:#1a092b;
$primary-color-2-light:#673996;
$primary-color-3:white;
$primary-color-3-inner-border:#D6D6D6;
$primary-color-4:#FBB03B;
$primary-color-4-light:#ffcb76;
$primary-color-4-dark:#b47e27;
$primary-color-5:#1ABFDD;

$warning-color:#CE310E;
$positive-color:#278D53;

$structure-color-1:#FAFAFA;
$structure-color-2:#F8F8F8;
$structure-color-3:#FFFDF8;
$structure-color-4:#E8EAED;
$structure-color-5:#B8C5D4;
$structure-color-6:#8C8C8C;
$structure-color-7:#434A52;
$structure-color-8:#E4E4E4;


$tablet-breakpoint:850px;


