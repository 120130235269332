@import '../../AppConstants.scss';

body{
    overflow-x: auto;
}

.bar{
    display: flex;

    justify-content: space-between;
    @media (max-width: $tablet-breakpoint) {
        justify-content: center;
    }
    align-items: center;
    padding:10px;
    row-gap: 4px;
    flex-wrap: wrap-reverse;
    background-color: $primary-color-3;
    box-shadow: inset 0px -1px 0px $structure-color-5;
    .left{
        margin-top:2px;
        margin-left: 20px;

        @media (max-width: $tablet-breakpoint) {
            //margin:0;
        }
    }
    .right{
        margin-right: 20px;
        @media (max-width: $tablet-breakpoint) {
            //margin:0;
        }
    }
    .select{
        height:36px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        border: 1px solid $primary-color-2;
        border-radius: 6px;
        color: $primary-color-2;
    }
    .spacer{
        height:38px;
        border-left:1px solid $structure-color-6;
        margin-right:20px;
    }
    .flexCenter{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap-reverse;
        gap:8px;
    }
}

@media (max-width: $tablet-breakpoint) {
    .hideMobile{
        display: none !important;
    }
}
