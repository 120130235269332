@import '../../AppConstants.scss';

body {
    background-color: $primary-color-2;
}


.screen{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.container {
    border-radius: 40px;
    background-color: $primary-color-3;
    width: 342px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h1{
        font-size: 18px;
        color:$primary-color-2;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;

        input {
            width: 260px;
            height: 38px;
            border-radius: 15px;
            padding-left: 10px;
            border: 2px solid $structure-color-6;
            font-size: 18px;
        }

        .forgotPass{
            margin:0;
            text-decoration: none;
            font-size: 14px;
            color:$structure-color-6;
            &:hover{
                text-decoration: underline;
            }
        }

        button {
            width: 100px;
            height: 38px;
            border-radius: 19px;
            background: linear-gradient(180deg, #FBB03B 0%, #F23DC2 100%);
            border: none;
            color:$primary-color-3;
            font-size: 14px;

            &:hover{
                box-shadow: 0px 0px 10px $primary-color-2;
            }
        }
    }
    a{
        margin-top:10px;
        margin-bottom: 18px;
        text-decoration: none;
        color:#F23DC2;
        &:hover{
            text-decoration: underline;
        }
    }
}