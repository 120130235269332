@import './AppConstants.scss';
body{
    background-color: $primary-color-2-light;
    font-family: 'Open Sans';
}

textarea:focus, input:focus{
    outline: none;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-color-2; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color-2-light; 
  }