@import '../../AppConstants.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.overlay{

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.678);
    left:0;
    top:0;
    display: flex;
    justify-content:center;
    align-items: center;

    .window{
        background-color: $primary-color-2;
        min-height: 200px;
        padding-bottom: 20px;
        width: 300px;
        border-radius: 10px;

        h1{
            font-size: 25px;
            margin:20px;
            color:$primary-color-3;
            text-shadow: 0px 0px 8px black;
        }
        .inputGroup{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-inline:20px;
            input{
                width: 100%;
                height: 45px;
                font-size: 24px;
                border: none;
                margin: 0;
                padding: 0;
                padding-inline: 5px;
            }
            button{
                height: 45px;
                background-color: $primary-color-4;
                border: none;
                width: 90px;
                color: $primary-color-3;
                font-size: 18px;
                text-shadow: 0px 0px 3px black;
                border-radius: 0 5px 5px 0;

                &:hover{
                    background-color: $primary-color-4-dark;
                    cursor: pointer;
                }
            }
        }
    }
}