@import '../../AppConstants.scss';

.row {
    width: auto;
    background-color: $primary-color-3;
    display: flex;
    border-bottom: 1px solid rgb(190, 190, 190);
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    padding-inline: 20px;
    flex-wrap: wrap;

    span {
        color: $primary-color-2;
        font-size: 18px;
        font-weight: 600;

        &.usedByProperty {
            width: 400px;

            .usedBy {
                color: black;
                font-weight: 400;
            }
        }

        .val {
            display: inline-block;
            color: black;
            font-weight: 600;
            text-align: justify;
            width: 120px;
        }
    }

    .used {
        width: 80px;
        color: red;
        font-weight: 400;
    }

    .notUsed {
        width: 80px;
        color: green;
        font-weight: 400;
    }
}

.iconWrapper {

    position: relative;

    .clipboard {
        color: black;
        height: 24px;
        width: 24px;
        margin-left: 10px;
        vertical-align: bottom;

        &:hover {
            cursor: pointer;
            color: $primary-color-1;
        }
    }

    .emailIcon{
        color: black;
        height: 24px;
        width: 24px;
        margin-left: 20px;
        vertical-align: bottom;

        &:hover {
            cursor: pointer;
            color: $primary-color-1;
        }
    }


    .tooltiptext {
        visibility: hidden;
        width: 130px;
        background-color: rgba(0, 0, 0, 0.349);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        margin-bottom: 2px;
        font-size: 12px;
        margin-top: 1px;

        /* Position the tooltip */
        position: absolute;
        left: -40px;
        bottom: -30px;
        z-index: 10;
    }

    &:hover .tooltiptext {
        visibility: visible;
    }

}