@import '../../AppConstants.scss';


.bar{
    width: 100%;
    background-color: $primary-color-3;
    height: 30px; 
    box-shadow: inset 0px -1px 0px $structure-color-5;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    color:$primary-color-2;
    font-size: 18px;
    &:hover{
        cursor: pointer;
    }
}