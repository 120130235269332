.container{
    margin-left: 186px;
    transition: 0.5s;
    &.collapsed{
        margin-left: 82px;
        transition: 0.5s;
    }
}

@media (max-width: 760px) {
    .container{
        margin-left: 82px;
        transition: 0.5s;
        &.collapsed{
            margin-left: 82px;
            transition: 0.5s;
        }
    }
}
