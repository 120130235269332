@import '../../AppConstants.scss';

.button{
    padding: 5px 15px 5px 15px;
    display: inline-flex;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    border-radius: 6px;
    align-items: center;

    &.secondary{
        border: 1px solid $primary-color-2;
        color: $primary-color-2;
        background:none;
        &:hover{
            cursor: pointer;
            background-color: $primary-color-4;
        }
    }

    &.primary{
        border:none;
        color: $primary-color-3;
        background-color:$primary-color-2;
        &:hover{
            cursor: pointer;
            background-color:$primary-color-2-dark;
        }
    }

    .icon{
        width:20px;
        height: 20px;
        margin-right: 10px;
    }
}