@import '../../AppConstants.scss';

.card{
    width: 250px;
    background-color: $primary-color-3;
    border-radius: 10px 10px 8px 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.521);

    &:hover{
        cursor: pointer;
    }

    img{
        height: 220px;
        width: 100%;
        border-radius: 8px 8px 0 0 ;
        object-fit: cover;
    }

    .row{
        margin:5px;
    }
}