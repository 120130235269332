@import '../../../AppConstants.scss';

.button{
    height:58px;
    width: 100%;
    color: $primary-color-3;
    display: flex;
    justify-content: left;
    align-items:center;
    background-color: $primary-color-2-dark;
    box-shadow: inset 0px 0.2px 0px $primary-color-3;
    &.selected{
        width: calc(100% - 3px);
        border-left: 3px solid $primary-color-3;
        background-color: $primary-color-2-light;
    }

    &:hover{
        background-color: $primary-color-2;
        cursor: pointer;
    }
    
}


.icon{
    margin-left: 25px;
    color:$primary-color-3;
    width:30px;
    height:30px;
}
.text{
    margin-left: 25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}