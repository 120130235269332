@import "../../AppConstants.scss";

.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.678);
    left:0;
    top:0;
    display: flex;
    justify-content:center;
    align-items: center;

    .modal{
        position: relative;
        min-width: 300px;
        max-width: 600px;
        min-height: 300px;
        border-radius: 15px 15px 10px 10px;
        background-color: $primary-color-3;
        box-shadow: 5px 5px 10px $primary-color-4;

        .header{
            border-radius: 10px 10px 0 0;
            border-bottom: 1px solid $primary-color-2-light;
            padding:20px;
            background-color: $primary-color-2;
            display: flex;
            justify-content:space-between;
            align-items: center;
            
            h1{
                margin:0;
                font-size: 20px;
                font-weight: 400;
                color: $primary-color-3;
            }

            .xIcon{
                color:$primary-color-3;
                width: 26px;
                height: 26px;
                &:hover{
                    cursor: pointer;
                }
            }
        }

        .body{
            padding:24px;
            p{
                margin: 0 0 24px 0 ;
                color: $primary-color-2;
            }
            margin-bottom:26px;
        }

        .footer{
            position: absolute;
            bottom:0;
            display: flex;
            justify-content: right;
            align-items: center;
            width: 100%;
            height: 50px;
            border-top: 1px dashed $primary-color-2-light;

            &>*{
                height:35px;
                margin-right: 20px;
                padding-inline:15px;
                border:none;
                background: none;
                border:2px solid $primary-color-2;
                color:$primary-color-2;
                font-weight: 600;
                border-radius: 6px;
                font-size: 14px;
                &:hover{
                    cursor:pointer;
                }
                &:focus{
                    outline: none;
                }
            }
        }

    }
}