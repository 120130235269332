@import '../../AppConstants.scss';

.container{
    width: 250px;
    height:38px;
    position: relative;
    background-color: $primary-color-3;

    .input{
        position: absolute;
        background-color: rgba(255, 255, 255, 0);
        top:0;
        left:0;
        z-index: 2;
        width: 236px;
        height:34px;
        border: 1px solid $structure-color-6;
        border-radius: 6px;
        padding-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        &:focus{
            outline: none;
        }
        &::placeholder{
            padding-left: 35px;
        }
    }
    .xBox{
        position: absolute;
        top:0px;
        right:0px;
        height: 38px;
        border-left: 1px solid $structure-color-6;
        z-index: 3;
        &:hover{
            cursor: pointer;
        }
        .xIcon{
            height:20px;
            width:20px;
            margin-top:9px;
            margin-right:4px;
            margin-left: 4px;
        }
    }
    .searchIcon{
        top:6px;
        left:10px;
        position: absolute;
        z-index: 1;
        height:24px;
        width:24px;
    }
}