@import '../../AppConstants.scss';

.emailForm{
    width: 600px !important;
}

.modalContent{
    max-height: 200px;
    margin-bottom: 60px;
    padding-left: 20px;
    overflow-y: auto;
}

.addCsvButton{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
}