@import '../../AppConstants.scss';


.button{
    position: fixed;
    background-color: $primary-color-4;
    height: 60px;
    width: 60px;
    z-index: 50;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.459);
    &:hover{
        background-color: $primary-color-4-dark;
    }

    @media (min-width: $tablet-breakpoint) {
        display: none;
    }
}