@import '../../AppConstants.scss';

.placeholder{
    display: flex;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: $primary-color-2;
    align-items: center;

    .icon{
        width:20px;
        height: 20px;
        &.left{    
            margin-right: 10px;
        }
        &.right{    
            margin-left: 10px;
        }
    }
}

.select__input {
    color: transparent;
  }